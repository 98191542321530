.app-bar {
  position: relative !important;
  z-index: 10000 !important;
}

.info {
  background-color: var(--linq-color-gray-200);
  padding-top: 10px;
}
.footer {
  background-color: var(--linq-color-primary-700);
}
